import { AsyncPipe, DatePipe, KeyValuePipe, LowerCasePipe, NgIf, NgTemplateOutlet } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconButton } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatTooltip } from '@angular/material/tooltip'
import { MatSelectModule } from '@angular/material/select'
import { MatInput } from '@angular/material/input'
import { MatTableModule } from '@angular/material/table'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatSortModule } from '@angular/material/sort'
import { MatMenuModule } from '@angular/material/menu'
import { MatRipple } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { NgxsFormPluginModule } from '@ngxs/form-plugin'

import { PopupComponent } from '../popup/popup.component'
import { JerseyNumberComponent, ProfilePictureComponent, UserActivationStatusComponent } from '../../components'
import { ButtonComponent } from '../../components/button/button.component'
import { PhonePipe } from '../../pipes/phone.pipe'
import { GeneralInfoComponent } from './general-info/general-info.component'
import { EditProfileComponent } from './edit-profile/edit-profile.component'
import { PhoneNumberComponent } from '../phone-number/phone-number.component'
import { UnpaidPaymentsComponent } from './unpaid-payments/unpaid-payments.component'
import { TableComponent } from '../table/table.component'
import { NewReminderComponent } from '../../../module/dashboard/tabs/economy-tab/new-reminder/new-reminder.component'
import { NewInvoiceModule } from '../../../module/dashboard/tabs/economy-tab/new-invoice/new-invoice.module'
import { InvoiceHistoryModule } from '../../../module/dashboard/tabs/economy-tab/invoice-history/invoice-history.module'
import { NewDugnadReminderComponent } from '../../../module/dashboard/tabs/economy-tab/new-dugnad-reminder/new-dugnad-reminder.component'
import { DugnadReminderHistoryComponent } from '../../../module/dashboard/tabs/economy-tab/dugnad-reminder-history/dugnad-reminder-history.component'
import { EditDugnadOrderComponent } from '../../../module/dashboard/tabs/economy-tab/edit-dugnad-order/edit-dugnad-order.component'
import { EditUserPaymentComponent } from '../../../module/dashboard/tabs/economy-tab/edit-user-payment/edit-user-payment.component'
import { CustomCurrencyPipe, GetNOKPipe, IsNumberPipe } from '../../pipes'
import { ReminderHistoryModule } from '../../../module/dashboard/tabs/economy-tab/reminder-history/reminder-history.module'
import { RelationsComponent } from './relations/relations.component'
import { ExpandYComponent } from '../../components/animations/expand-y.component'
import { ScrollShadowDirective } from '../../directives/scroll-shadow.directive'
import { LoaderComponent } from '../loader/loader.component'
import { MembershipsComponent } from './memberships/memberships.component'
import { FadeInComponent } from '../../components/animations/fade-in.component'
import { GetFullNamePipe } from '../../pipes/get-full-name.pipe'
import { ModalComponent } from '../modal/modal.component'
import { InfoBlockComponent } from '../../components/info-block/info-block.component'
import { EditMembershipComponent } from './edit-membership/edit-membership.component'

@NgModule({
  declarations: [
    GeneralInfoComponent,
    EditProfileComponent,
    MembershipsComponent,
    EditMembershipComponent,
    RelationsComponent,
    UnpaidPaymentsComponent
  ],
  imports: [
    NgIf,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    TranslateModule,
    MatFormFieldModule,
    MatInput,
    MatIconButton,
    MatIcon,
    MatCheckbox,
    MatMenuModule,
    MatSelectModule,
    MatTooltip,
    MatRipple,
    MatTableModule,
    MatSortModule,
    PhoneNumberComponent,
    PopupComponent,
    ModalComponent,
    NewReminderComponent,
    ReminderHistoryModule,
    NewInvoiceModule,
    InvoiceHistoryModule,
    EditUserPaymentComponent,
    NewDugnadReminderComponent,
    DugnadReminderHistoryComponent,
    EditDugnadOrderComponent,
    ProfilePictureComponent,
    UserActivationStatusComponent,
    JerseyNumberComponent,
    ButtonComponent,
    TableComponent,
    ExpandYComponent,
    FadeInComponent,
    InfoBlockComponent,
    LoaderComponent,
    DatePipe,
    LowerCasePipe,
    AsyncPipe,
    KeyValuePipe,
    CustomCurrencyPipe,
    PhonePipe,
    GetNOKPipe,
    IsNumberPipe,
    GetFullNamePipe,
    ScrollShadowDirective
  ],
  exports: [
    GeneralInfoComponent,
    EditProfileComponent,
    MembershipsComponent,
    EditMembershipComponent,
    RelationsComponent,
    UnpaidPaymentsComponent
  ]
})
export class UserProfileModule {}
