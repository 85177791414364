import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Store } from '@ngxs/store'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { UserProfileComponent } from '../user-profile.component'
import { DashboardState } from '../../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../../core/interfaces/organization/organization'
import { MembershipResponse } from './interfaces/membership-response'
import { OrgService } from '../../../../core/api'
import { UserProfileState } from '../state/user-profile.state'
import { Popup } from '../../../../core/classes/global/popup'
import { Modal } from '../../../../core/classes/global/modal'
import { SnackbarService } from '../../../components/snackbar/snackbar.service'
import { UpdateUserMembership } from '../state/user-profile.actions'
import { Logout } from '../../../states/global.actions'

@Component({
  selector: 'app-memberships',
  templateUrl: 'memberships.component.html',
  styleUrl: 'memberships.component.scss'
})
export class MembershipsComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization
  @SelectSnapshot(UserProfileState.userId) userId: number

  @Input({ required: true }) hostComponent: UserProfileComponent

  @Output() editMembership: EventEmitter<{
    memberships: MembershipResponse[]
    selectedMembership: MembershipResponse
  }> = new EventEmitter()

  loading: boolean
  memberships: MembershipResponse[] = []
  selectedMembership: MembershipResponse | null = null
  deleteMembershipModal: Modal = new Modal()
  deleteMultipleMembershipsPopup: Popup = new Popup()
  deleteAsPlayerField: FormControl<boolean> = new FormControl(false, { nonNullable: true })
  deleteAsAdminField: FormControl<boolean> = new FormControl(false, { nonNullable: true })

  get isLastAdminMembership(): boolean {
    return (
      this.hostComponent.isCurrentUser &&
      !this.memberships
        .filter((membership) => membership !== this.selectedMembership)
        .some((membership) => membership.is_admin)
    )
  }

  constructor(
    private store: Store,
    private translate: TranslateService,
    private orgService: OrgService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.loadMemberships()
  }

  getTitleNames(titles: { id: number; title: string }[]): string {
    return titles.map((title) => title.title).join(', ')
  }

  private loadMemberships(): void {
    this.loading = true
    this.orgService
      .getMemberships(this.organization.id, this.userId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => (this.memberships = response))
  }

  openEditMembership(membership: MembershipResponse): void {
    this.editMembership.emit({
      memberships: this.memberships,
      selectedMembership: membership
    })
  }

  openDeleteMembership(membership: MembershipResponse): void {
    this.selectedMembership = membership
    this.deleteAsPlayerField.patchValue(false)
    this.deleteAsAdminField.patchValue(false)

    if (membership.is_player && membership.is_admin) {
      this.deleteMultipleMembershipsPopup.open()
    } else {
      this.deleteMembershipModal.open()
    }
  }

  confirmDeleteMembership(options?: { deleteAsPlayer: boolean; deleteAsAdmin: boolean }): void {
    const payload: { is_admin?: boolean; is_player?: boolean } = {}
    const popup: Popup | Modal = options ? this.deleteMultipleMembershipsPopup : this.deleteMembershipModal

    if (!options || options.deleteAsPlayer) {
      payload.is_player = false
    }

    if (!options || options.deleteAsAdmin) {
      payload.is_admin = false
    }

    popup.showLoader()
    this.orgService
      .updateMembership(this.selectedMembership!.id, payload)
      .pipe(finalize(() => popup.hideLoader()))
      .subscribe(() => {
        const toasterParams = {
          user: `${this.hostComponent.user!.first_name} ${this.hostComponent.user!.last_name}`,
          group: this.selectedMembership!.group.name
        }

        switch (true) {
          case options?.deleteAsPlayer && !options?.deleteAsAdmin:
            this.snackbarService.info(this.translate.instant('toaster.no_longer_player', toasterParams))
            break
          case options?.deleteAsAdmin && !options?.deleteAsPlayer:
            this.snackbarService.info(this.translate.instant('toaster.no_longer_admin', toasterParams))
            break
          default:
            this.snackbarService.info(this.translate.instant('toaster.no_longer_member', toasterParams))
            break
        }

        if (this.isLastAdminMembership) {
          this.store.dispatch(new Logout())
        } else {
          this.loadMemberships()
          this.closeDeleteMembership()
          this.store.dispatch(new UpdateUserMembership())
        }
      })
  }

  closeDeleteMembership(): void {
    this.deleteMembershipModal.close()
    this.deleteMultipleMembershipsPopup.close()
    this.selectedMembership = null
  }
}
