<form [formGroup]="membershipForm" [ngxsForm]="membershipFormNgxsFormPath">
  <div class="section-header">
    <p class="ns-subtitle">{{ 'user_profile.memberships' | translate }}</p>
  </div>

  <div class="roles">
    <div class="ns-checkbox-field">
      <mat-checkbox formControlName="isPlayer">
        <mat-icon>person</mat-icon>
        <div>
          <p class="bold">{{ 'user_types.player' | translate }}</p>
          <p>{{ 'user_profile.memberships_player_description' | translate }}</p>
        </div>
      </mat-checkbox>
    </div>

    <div class="ns-checkbox-field">
      <mat-checkbox formControlName="isAdmin">
        <mat-icon>manage_accounts</mat-icon>
        <div>
          <p class="bold">{{ 'user_types.admin' | translate }}</p>
          <p>{{ 'user_profile.memberships_admin_description' | translate }}</p>
        </div>
      </mat-checkbox>
    </div>

    @if (hostComponent.isLastAdminMembership && !membershipForm.value.isAdmin) {
      <app-expand-y>
        <app-info-block type="warning" [title]="'user_profile.remove_from_group.website_access_lost_title' | translate">
          {{
            'user_profile.remove_from_group.website_access_lost_description'
              | translate: { organization: organization.name }
          }}
        </app-info-block>
      </app-expand-y>
    }
  </div>

  @if (membershipForm.value.isPlayer) {
    <app-expand-y class="section">
      <div class="divider"></div>

      <div class="section-header">
        <p class="ns-subtitle">{{ 'user_attributes.jersey_number' | translate }}</p>
      </div>

      <mat-form-field class="jersey-number ns-no-gap">
        <mat-label>{{ 'user_attributes.jersey_number' | translate }}</mat-label>
        <input matInput type="number" formControlName="jerseyNumber" [min]="0" />
      </mat-form-field>
    </app-expand-y>
  }

  @if (membershipForm.value.isAdmin) {
    <app-expand-y class="section">
      <div class="divider"></div>

      <div class="section-header">
        <p class="ns-subtitle">{{ 'user_profile.roles' | translate }}</p>
        <p class="ns-text small">
          {{ 'user_profile.memberships_roles_description' | translate }}
          <a
            href="https://support.hoopit.io/nb/articles/9546155-titler"
            target="_blank"
            class="ns-button ns-button--transparent ns-button--small ns-button--text"
          >
            {{ 'user_profile.memberships_roles_article_button' | translate }}
          </a>.
        </p>
      </div>

      <ul class="user-titles">
        @for (title of membershipForm.value.titles; track title.id) {
          <li @titleAnimation class="ns-subtitle small">
            <div class="title">
              {{ title.title }}
              <button matRipple (click)="removeTitle(title.id)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </li>
        }
      </ul>

      <div class="manage-roles-wrapper">
        <app-button theme="light" size="small" icon="edit" (click)="openTitlesPopup()">
          {{ 'user_profile.memberships_manage_roles' | translate }}
        </app-button>
      </div>
    </app-expand-y>
  }
</form>

<app-popup
  *ngIf="titlesPopup.isOpen"
  [header]="'user_profile.roles' | translate"
  [loading]="titlesPopup.loading"
  [isDismissible]="!titlesPopup.loading"
  (popupClose)="titlesPopup.close()"
>
  <div [formGroup]="titlesForm" class="popup__body ns-form">
    <p
      class="titles-popup-description ns-text default-text-color"
      [innerHTML]="
        'user_profile.manage_roles_description'
          | translate: { user: hostComponent.user | getFullName, group: membership.group.name }
      "
    ></p>

    <mat-form-field>
      <mat-label>{{ 'miscellaneous.search' | translate }}</mat-label>
      <input matInput formControlName="search" autocomplete="off" />

      @if (titlesForm.value.search) {
        <button
          type="button"
          aria-label="clear"
          mat-icon-button
          matSuffix
          (click)="resetSearch()"
        >
          <mat-icon>clear</mat-icon>
        </button>
      } @else {
        <mat-icon matSuffix>search</mat-icon>
      }
    </mat-form-field>

    @if (availableTitles.length) {
      <app-expand-y formGroupName="titles" class="available-titles-expand ns-checkbox-filters">
        <ul [appScrollShadow]="['top', 'bottom']">
          @for (title of filteredTitles; track title.id) {
            <li class="ns-checkbox-filter">
              <mat-checkbox [formControlName]="title.id">{{ title.title }}</mat-checkbox>
            </li>
          }
        </ul>
      </app-expand-y>
    }

    <div class="popup__button-wrapper">
      <app-button theme="transparent" size="small" (click)="titlesPopup.close()">
        {{ 'action.cancel' | translate }}
      </app-button>
      <app-button size="small" (click)="confirmTitles()">
        {{ 'action.confirm' | translate }}
      </app-button>
    </div>
  </div>
</app-popup>
