@if (loading) {
  <app-loader [size]="60"></app-loader>
} @else if (!memberships.length) {
  <div class="empty-state">
    <mat-icon>groups</mat-icon>
    <p class="ns-subtitle small">{{ 'user_profile.memberships_empty_state_title' | translate }}</p>
    <p class="ns-text small">
      {{
        'user_profile.memberships_empty_state_description'
          | translate: { userName: hostComponent.user | getFullName, organization: organization.name }
      }}
    </p>
  </div>
}

@if (memberships.length) {
  <app-fade-in class="memberships">
    @for (membership of memberships; track membership.id) {
      <div class="membership">
        <div class="membership__info">
          <div class="membership__group">
            @if (membership.parent_groups) {
              <p class="parent-groups ns-text small">
                @for (group of membership.parent_groups; track $index; let last = $last) {
                  {{ group }}

                  @if (!last) {
                    <span class="divider">·</span>
                  }
                }
              </p>
            }

            <div class="group-name">
              @if (membership.group.logo) {
                <img [src]="membership.group.logo" alt="Group logo" />
              }

              <h6>{{ membership.group.name }}</h6>
            </div>
          </div>

          <div class="membership__details">
            <div class="role">
              @switch (true) {
                @case (membership.is_player && membership.is_admin) {
                  <i class="icon icon-user-star"></i>
                  <p class="ns-subtitle small">{{ 'user_types.player_and_admin' | translate }}</p>
                }
                @case (membership.is_player) {
                  <mat-icon>person</mat-icon>
                  <p class="ns-subtitle small">{{ 'user_types.player' | translate }}</p>
                }
                @case (membership.is_admin) {
                  <mat-icon>manage_accounts</mat-icon>
                  <p class="ns-subtitle small">{{ 'user_types.admin' | translate }}</p>
                }
              }
            </div>

            @if (membership.roles.length || membership.jersey_number) {
              <div class="badges">
                @if (membership.jersey_number) {
                  <span class="badge">
                    <i class="icon icon-t-shirt"></i>
                    {{ membership.jersey_number }}
                  </span>
                }

                @if (membership.roles) {
                  @let titlesToShow = membership.jersey_number ? 2 : 3;

                  @for (title of membership.roles.slice(0, titlesToShow); track $index; let last = $last) {
                    @if (last && $index < membership.roles.length - 1) {
                      <span
                        class="badge"
                        [matTooltip]="getTitleNames(membership.roles.slice(titlesToShow - 1))"
                        matTooltipClass="ns-light"
                        matTooltipPosition="above"
                      >
                        +{{ membership.roles.length - $index }} more
                      </span>
                    } @else {
                      <span class="badge">{{ title.title }}</span>
                    }
                  }
                }
              </div>
            }
          </div>
        </div>

        <div class="membership__buttons">
          <button
            mat-icon-button
            [disabled]="!membership.can_edit"
            class="edit"
            (click)="openEditMembership(membership)"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            mat-icon-button
            [disabled]="!membership.can_delete"
            class="delete"
            (click)="openDeleteMembership(membership)"
          >
            <mat-icon>delete</mat-icon>
          </button>

          <button
            mat-icon-button
            [disabled]="!membership.can_edit && !membership.can_delete"
            class="more"
            [matMenuTriggerFor]="actionsMenu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu xPosition="after" class="ns-order-status-menu" #actionsMenu="matMenu">
            <button
              mat-menu-item
              [disabled]="!membership.can_edit"
              (click)="openEditMembership(membership)"
            >
              {{ 'action.edit' | translate }}
            </button>
            <button
              mat-menu-item
              class="red"
              [disabled]="!membership.can_delete"
              (click)="openDeleteMembership(membership)"
            >
              {{ 'action.remove' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
    }
  </app-fade-in>
}

@if (deleteMembershipModal.isOpen) {
  <app-modal
    [header]="'user_profile.remove_from_group_title' | translate"
    [loading]="deleteMembershipModal.loading"
    [isDismissible]="!deleteMembershipModal.loading"
    (modalClose)="closeDeleteMembership()"
  >
    <div class="modal__body">
      <p
        [innerHTML]="
          'user_profile.remove_from_group_description'
            | translate: { user: hostComponent.user | getFullName, group: selectedMembership!.group.name }
        "
        class="default-text-color ns-text"
      ></p>

      @if (isLastAdminMembership) {
        <app-info-block type="warning" [title]="'user_profile.remove_from_group.website_access_lost_title' | translate">
          {{
            'user_profile.remove_from_group.website_access_lost_description'
              | translate: { organization: organization.name }
          }}
        </app-info-block>
      }
    </div>

    <div class="modal__button-wrapper">
      <app-button theme="transparent" size="small" (click)="closeDeleteMembership()">
        {{ 'action.no_go_back' | translate }}
      </app-button>
      <app-button size="small" (click)="confirmDeleteMembership()">
        {{ 'action.yes_remove' | translate }}
      </app-button>
    </div>
  </app-modal>
}

@if (deleteMultipleMembershipsPopup.isOpen) {
  <app-popup
    [header]="'user_profile.remove_from_group_title' | translate"
    [loading]="deleteMultipleMembershipsPopup.loading"
    [isDismissible]="!deleteMultipleMembershipsPopup.loading"
    (popupClose)="closeDeleteMembership()"
  >
    <div class="popup__body">
      <p
        [innerHTML]="
          'user_profile.remove_from_group_description'
            | translate: { user: hostComponent.user | getFullName, group: selectedMembership!.group.name }
        "
        class="default-text-color ns-text"
      ></p>

      <div class="ns-checkbox-filters">
        <div class="ns-checkbox-filter">
          <mat-checkbox [formControl]="deleteAsPlayerField">
            {{ 'user_profile.remove_as_player' | translate }}
          </mat-checkbox>
        </div>

        <div class="ns-checkbox-filter">
          <mat-checkbox [formControl]="deleteAsAdminField">
            {{ 'user_profile.remove_as_admin' | translate }}
          </mat-checkbox>
        </div>
      </div>

      @if (isLastAdminMembership) {
        <app-info-block type="warning" [title]="'user_profile.remove_from_group.website_access_lost_title' | translate">
          {{
            'user_profile.remove_from_group.website_access_lost_description'
              | translate: { organization: organization.name }
          }}
        </app-info-block>
      }

      <div class="popup__button-wrapper">
        <app-button theme="transparent" size="small" (click)="closeDeleteMembership()">
          {{ 'action.cancel' | translate }}
        </app-button>
        <app-button
          size="small"
          [disabled]="!deleteAsPlayerField.value && !deleteAsAdminField.value"
          (click)="confirmDeleteMembership({ deleteAsPlayer: deleteAsPlayerField.value, deleteAsAdmin: deleteAsAdminField.value })"
        >
          {{ 'action.confirm' | translate }}
        </app-button>
      </div>
    </div>
  </app-popup>
}
