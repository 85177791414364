import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxsModule } from '@ngxs/store'
import { NgxsFormPluginModule } from '@ngxs/form-plugin'
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'
import { TranslateModule } from '@ngx-translate/core'
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot'
import { LightboxModule } from 'ngx-lightbox'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core'
import { MatOptionModule } from '@angular/material/core'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatGridListModule } from '@angular/material/grid-list'
import { MAT_ICON_DEFAULT_OPTIONS, MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTableModule } from '@angular/material/table'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { ServiceWorkerModule } from '@angular/service-worker'
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, MatSlideToggleModule } from '@angular/material/slide-toggle'
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance'
import { registerLocaleData } from '@angular/common'
import localeNb from '@angular/common/locales/nb'
import localeUk from '@angular/common/locales/uk'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatSelectCountryModule } from '@angular-material-extensions/select-country'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config'
import * as Sentry from '@sentry/angular-ivy'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'

import { ngxsConfig } from './config/ngxs.config'
import { AppComponent } from './app.component'
import {
  ActivateRedirectGuard,
  GroupRegistrationRedirectGuard,
  ManageMembershipRedirectGuard,
  ROUTES
} from './app.routes'
import { LOADER_STATES, NON_PERSISTED_STATES, PERSISTED_STATES } from './shared/states'
import { TRANSLATE_MODULE_CONFIG } from './config/translate.config'
import { environment } from '../environments/environment'
import { NotSupportedBrowserComponent } from './module/not-supported-browser/not-supported-browser.component'
import { DashboardComponent } from './module/dashboard/dashboard.component'
import {
  AuthService,
  ExternalService,
  IntercomService,
  RequestService,
  StripeService,
  UtilsService,
  ValidationService
} from './core/services'
import {
  BrowserGuard,
  DashboardAuthGuard,
  ImportedMembersGuard,
  LoginGuard,
  MembersGuard,
  SMSConfigGuard
} from './core/guards'
import { SharedModule } from './shared/modules/shared.module'
import { DateTimePickerModule } from './shared/modules/date-time-picker/date-time-picker.module'
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler-interceptor'
import { RequestVersionInterceptor } from './core/interceptors/request-version-interceptor'
import { errorHandlerFactory } from './core/handlers/error-handler-factory'
import { HttpCancelInterceptor } from './core/interceptors/http-cancel-interceptor'
import { HttpCancelService } from './core/services/http-cancel.service'
import { HeaderInterceptor } from './core/interceptors/header-interceptor'
import { WINDOW, WindowFactory, WindowService } from './core/services/window.service'
import { UsersService } from './core/api'
import { AddPlayerModule } from './shared/modules/add-player/add-player.module'
import { AddGuardianModule } from './shared/modules/add-guardian/add-guardian.module'
import { AddAdminModule } from './shared/modules/add-admin/add-admin.module'
import { NothingFoundComponent } from './module/nothing-found/nothing-found.component'
import { PhoneNumberComponent } from './shared/modules/phone-number/phone-number.component'
import { AppCheckInterceptor } from './core/interceptors/app-check-interceptor'
import { FACEBOOK_PIXEL_PROVIDER } from './core/providers/facebook-pixel.provider'
import { PaymentsGuard } from './core/guards/payments.guard'
import { LoginComponent } from './module/login/login.component'
import { RemoteConfigKeys } from './core/enums/global/remote-config-keys'
import { FeatureFlagGuard } from './core/guards/feature-flag.guard'
import { FeatureFlagDirective } from './shared/directives/feature-flag.directive'
import { UserProfileComponent } from './shared/modules/user-profile/user-profile.component'
import { ApplicationUpdateService } from './core/services/application-update.service'
import { SnackbarComponent } from './shared/components'
import { RequestPasswordResetComponent } from './shared/modules/request-password-reset/request-password-reset.component'

registerLocaleData(localeNb)
registerLocaleData(localeUk)

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@NgModule({
  declarations: [AppComponent, DashboardComponent, NothingFoundComponent, NotSupportedBrowserComponent],
  bootstrap: [AppComponent],
  imports: [
    // TODO: Add separate Routing Module
    RouterModule.forRoot(ROUTES),
    // TODO: Use NgxsModule.forFeature for States of lazy-loaded modules
    NgxsModule.forRoot([...PERSISTED_STATES, ...NON_PERSISTED_STATES, ...LOADER_STATES], ngxsConfig),
    NgxsStoragePluginModule.forRoot({
      key: PERSISTED_STATES
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Ngxs Hoopit DevTools'
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.ngxsLoggerEnabled
    }),
    NgxsSelectSnapshotModule.forRoot(),
    MatSelectCountryModule.forRoot('en'),
    NgxGoogleAnalyticsModule.forRoot(environment.firebaseConfig.measurementId),
    LightboxModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatGridListModule,
    MatListModule,
    MatOptionModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatRippleModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    DateTimePickerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    BrowserAnimationsModule,
    SharedModule,
    AddPlayerModule,
    AddGuardianModule,
    AddAdminModule,
    SnackbarComponent,
    LoginComponent,
    RequestPasswordResetComponent,
    UserProfileComponent,
    ServiceWorkerModule.register('ngsw-worker.js'),
    PhoneNumberComponent,
    FeatureFlagDirective
  ],
  providers: [
    ApplicationUpdateService,
    UtilsService,
    AuthService,
    RequestService,
    StripeService,
    ValidationService,
    IntercomService,
    ExternalService,
    HttpCancelService,
    DashboardAuthGuard,
    LoginGuard,
    BrowserGuard,
    FeatureFlagGuard,
    ImportedMembersGuard,
    MembersGuard,
    SMSConfigGuard,
    PaymentsGuard,
    ActivateRedirectGuard,
    GroupRegistrationRedirectGuard,
    ManageMembershipRedirectGuard,
    PerformanceMonitoringService,
    provideCharts(withDefaultRegisterables()),
    FACEBOOK_PIXEL_PROVIDER,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } },
    { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-icons-round' } },
    { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { hideIcon: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'nb' },
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [UsersService] },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestVersionInterceptor, multi: true },
    { provide: WINDOW, useFactory: WindowFactory, deps: [WindowService] },
    { provide: HTTP_INTERCEPTORS, useClass: AppCheckInterceptor, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideRemoteConfig(() => {
      const remoteConfig = getRemoteConfig(getApp())
      const defaultConfig: Record<RemoteConfigKeys, number | boolean | string> = {
        // TODO: Change to empty string or store in proper way?
        [RemoteConfigKeys.ConfigPostFilesValidations]:
          '{"max_megabytes_size":30,"max_count":20,"supported_extensions":["pdf","doc","docx","xls","xlsx","csv","txt","rtf","jpg","jpeg","gif","png"]}',
        [RemoteConfigKeys.ConfigPostAttachmentFileTypes]: 'image,doc,pdf,video,compress,xls,ppt',
        [RemoteConfigKeys.FeatureStreamChat]: !environment.production,
        [RemoteConfigKeys.FeatureDugnads]: !environment.production,
        [RemoteConfigKeys.FeatureConflictingEvents]: !environment.production,
        [RemoteConfigKeys.FeatureUpdatePatchEventEP]: !environment.production,
        [RemoteConfigKeys.FeatureApproveNewMembers]: !environment.production
      }
      remoteConfig.defaultConfig = defaultConfig
      remoteConfig.settings.minimumFetchIntervalMillis = environment.production ? 60000 : 5000
      remoteConfig.settings.fetchTimeoutMillis = 10000
      return remoteConfig
    })
  ]
})
export class AppModule {}
