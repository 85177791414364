<ng-container [formGroup]="hostComponent.userProfileForm">
  <div class="container">
    <div *ngIf="hostComponent.isCurrentUser" class="profile-picture-wrapper">
      <app-profile-picture
        [user]="hostComponent.user!"
        [updatable]="hostComponent.isCurrentUser"
        [deletable]="true"
        (profilePictureUpdated)="hostComponent.updateProfilePicture($event)"
      ></app-profile-picture>
    </div>

    <div class="ns-inline-fields ns-form-row">
      <mat-form-field class="ns-width-50">
        <mat-label>{{ 'user_attributes.first_name' | translate }}</mat-label>
        <input matInput formControlName="firstName" />
        <mat-icon *ngIf="hostComponent.userProfileForm.controls.firstName.invalid" matSuffix>error_outline</mat-icon>
      </mat-form-field>

      <mat-form-field class="ns-width-50">
        <mat-label>{{ 'user_attributes.last_name' | translate }}</mat-label>
        <input matInput formControlName="lastName" />
        <mat-icon *ngIf="hostComponent.userProfileForm.controls.lastName.invalid" matSuffix>error_outline</mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>{{ 'user_attributes.birth_date' | translate }}</mat-label>
      <input matInput type="date" formControlName="dateOfBirth" [max]="$any(today)" />
      <mat-icon matPrefix>calendar_today</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'user_attributes.gender' | translate }}</mat-label>
      <mat-select formControlName="gender">
        <mat-option [value]="Gender.Male">{{ 'gender.male' | translate }}</mat-option>
        <mat-option [value]="Gender.Female">{{ 'gender.female' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="container">
    <p class="form-sub-header ns-subtitle">{{ 'organization.contact_info' | translate }}</p>

    <app-phone-number
      [phoneCode]="hostComponent.userProfileForm.value.phoneCountryCode!"
      [phoneNumber]="hostComponent.userProfileForm.value.phoneMobile!"
      [required]="hostComponent.contactInfoRequired && hostComponent.isCurrentUser"
      (phoneNumberInput)="handlePhoneNumberUpdate($event)"
    ></app-phone-number>

    <mat-form-field>
      <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
      <input matInput formControlName="email" />
      <mat-icon *ngIf="hostComponent.userProfileForm.controls.email.invalid" matSuffix>error_outline</mat-icon>
    </mat-form-field>
  </div>

  <ng-container *ngIf="hostComponent.isCurrentUser">
    <div class="divider"></div>

    <div class="container">
      <p class="form-sub-header ns-subtitle">{{ 'user_attributes.address' | translate }}</p>

      <div class="ns-inline-fields ns-form-row">
        <mat-form-field class="ns-width-50">
          <mat-label>{{ 'user_attributes.postal_code' | translate }}</mat-label>
          <input matInput formControlName="postalCode" />
        </mat-form-field>
        <mat-form-field class="ns-width-50">
          <mat-label>{{ 'user_attributes.city' | translate }}</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>
      </div>
      <mat-form-field class="ns-address">
        <mat-label>{{ 'user_attributes.address' | translate }}</mat-label>
        <input matInput formControlName="streetAddress" />
      </mat-form-field>
    </div>
  </ng-container>

  <div class="divider"></div>

  <div class="container">
    <p class="sub-header ns-subtitle">{{ 'user_profile.permissions' | translate }}</p>
    <div class="photo-permission-description">
      <p *ngIf="hostComponent.isCurrentUser" class="ns-text small">
        {{ 'user_attributes.permissions_text' | translate }}
      </p>
      <p *ngIf="!hostComponent.isCurrentUser" class="ns-text small">
        {{
          'user_attributes.permissions_text_2'
            | translate: { name: hostComponent.user!.first_name + ' ' + hostComponent.user!.last_name }
        }}
      </p>
      <p class="ns-text small">{{ 'user_attributes.permissions_description' | translate }}</p>
    </div>

    <mat-form-field>
      <mat-label>{{ 'user_attributes.photo_permission' | translate }}</mat-label>
      <mat-select formControlName="mediaPrivacyPermission">
        <ng-container *ngIf="hostComponent.isCurrentUser">
          <mat-option value="no_permission">{{ 'user_attributes.no_permission' | translate }}</mat-option>
          <mat-option value="internal">{{ 'user_attributes.permission_internal_text' | translate }}</mat-option>
          <mat-option value="public">{{ 'user_attributes.permission_public_text' | translate }}</mat-option>
        </ng-container>
        <ng-container *ngIf="!hostComponent.isCurrentUser">
          <mat-option value="no_permission">{{ 'user_attributes.no_permission_label' | translate }}</mat-option>
          <mat-option value="internal">{{ 'user_attributes.permission_internal_label' | translate }}</mat-option>
          <mat-option value="public">{{ 'user_attributes.permission_public_label' | translate }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  @if (hostComponent.customFields.length) {
    <div class="divider"></div>

    <p class="form-sub-header ns-subtitle">{{ 'user_profile.additional_information' | translate }}</p>

    <div class="custom-fields container" formGroupName="customFields">
      @for (iterator of hostComponent.customFieldsGrouped | keyvalue; track $index) {
        <p class="group-name ns-subtitle small">
          {{ iterator.value.groupName }}

          @if (iterator.value.parentGroupName) {
            ({{ iterator.value.parentGroupName }})
          }
        </p>

        @for (field of iterator.value.customFields; track $index) {
          @switch (field.type) {
            @case ('text') {
              <mat-form-field>
                <mat-label>{{ field.name }}</mat-label>
                <input matInput [formControlName]="field.id" />
              </mat-form-field>
            }
            @case ('integer') {
              <mat-form-field>
                <mat-label>{{ field.name }}</mat-label>
                <input type="number" matInput [formControlName]="field.id" />
              </mat-form-field>
            }
            @case ('date') {
              <mat-form-field>
                <mat-label>{{ field.name }}</mat-label>
                <input type="date" matInput [formControlName]="field.id" />
              </mat-form-field>
            }
            @case ('time') {
              <mat-form-field>
                <mat-label>{{ field.name }}</mat-label>
                <input type="time" matInput [formControlName]="field.id" />
              </mat-form-field>
            }
            @case ('datetime') {
              <mat-form-field>
                <mat-label>{{ field.name }}</mat-label>
                <input type="datetime" matInput [formControlName]="field.id" />
              </mat-form-field>
            }
            @case ('boolean') {
              <div class="ns-checkbox-field">
                <mat-checkbox [formControlName]="field.id">
                  <div class="ns-label-header">
                    <span class="bold">{{ field.name }}</span>
                  </div>
                </mat-checkbox>
              </div>
            }
          }
        }
      }
    </div>
  }
</ng-container>
