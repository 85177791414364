<div
  class="main-block"
  [class.main-block--mobile-details-visible]="mobileView === 'details'"
  (click)="$event.stopPropagation()"
>
  @if (loading) {
    <app-loader class="mobile-loader" [size]="80"></app-loader>
  }

  <div class="inner-wrapper">
    <div [@sidebarAnimation]="!user && !isSmallScreen" class="sidebar">
      @if (user) {
        <section class="sidebar__header">
          @if (this.listOfPreviouslyOpenedUsers.length > 1 && !loading) {
            <app-expand-x class="open-previous-profile">
              <button mat-icon-button (click)="openPreviousProfile()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </app-expand-x>
          }

          <h6 @nameMobileAnimation [@.disabled]="!isSmallScreen" class="name">{{ user.first_name }}</h6>

          <button class="close-profile-button" mat-icon-button (click)="closeProfile()">
            <mat-icon>close</mat-icon>
          </button>
        </section>

        <!-- TODO: set up two separate animations based on "isSmallScreen" -->
        <!-- [@tabsAnimation]="!isSmallScreen" [@tabsMobileAnimation]="isSmallScreen" -->
        <ul @tabsAnimation>
          <li
            matRipple
            matRippleColor="#dfdfe750"
            [matRippleDisabled]="!isSmallScreen"
            [class.active]="activeTab === UserProfileTab.Info"
          >
            <button appBlurOnClick (click)="openTab(UserProfileTab.Info)">
              <app-profile-picture [user]="user"></app-profile-picture>
              <span>{{ 'user_profile.profile' | translate }}</span>
              <mat-icon class="mobile-icon">chevron_right</mat-icon>
            </button>
          </li>

          @if (user.can_send_invitation) {
            <li
              matRipple
              matRippleColor="#dfdfe750"
              [matRippleDisabled]="!isSmallScreen"
              [class.active]="activeTab === UserProfileTab.Invitation"
            >
              <button appBlurOnClick (click)="openTab(UserProfileTab.Invitation)">
                <mat-icon class="label-icon">how_to_reg</mat-icon>
                <span>{{ 'user_profile.invitation' | translate }}</span>
                <mat-icon class="mobile-icon">chevron_right</mat-icon>
              </button>
            </li>
          }

          <li
            matRipple
            matRippleColor="#dfdfe750"
            [matRippleDisabled]="!isSmallScreen"
            [class.active]="activeTab === UserProfileTab.Memberships"
          >
            <button appBlurOnClick (click)="openTab(UserProfileTab.Memberships)">
              <mat-icon class="label-icon">groups</mat-icon>
              <span>{{ 'user_profile.memberships' | translate }}</span>
              <mat-icon class="mobile-icon">chevron_right</mat-icon>
            </button>
          </li>

          <li
            matRipple
            matRippleColor="#dfdfe750"
            [matRippleDisabled]="!isSmallScreen"
            [class.active]="activeTab === UserProfileTab.Relations"
          >
            <button appBlurOnClick (click)="openTab(UserProfileTab.Relations)">
              <mat-icon class="label-icon">supervised_user_circle</mat-icon>
              <span>{{ 'user_profile.relations' | translate }}</span>
              <mat-icon class="mobile-icon">chevron_right</mat-icon>
            </button>
          </li>

          <li
            matRipple
            matRippleColor="#dfdfe750"
            [matRippleDisabled]="!isSmallScreen"
            [class.active]="activeTab === UserProfileTab.Payments"
          >
            <button appBlurOnClick (click)="openTab(UserProfileTab.Payments)">
              <mat-icon class="label-icon">monetization_on</mat-icon>
              <span>{{ 'user_profile.unpaid_payments' | translate }}</span>
              <mat-icon class="mobile-icon">chevron_right</mat-icon>
            </button>
          </li>

          @if (!isCurrentUser && user.can_message) {
            <li
              matRipple
              matRippleColor="#dfdfe750"
              [matRippleDisabled]="!isSmallScreen"
              [class.active]="activeTab === UserProfileTab.Chat"
            >
              <button appBlurOnClick (click)="openTab(UserProfileTab.Chat)">
                <mat-icon class="label-icon">chat</mat-icon>
                <span>{{ 'organization.chat' | translate }}</span>
                <mat-icon class="mobile-icon">chevron_right</mat-icon>
              </button>
            </li>
          }
        </ul>
      }
    </div>

    <section class="content">
      @if (loading) {
        <app-loader class="desktop-loader" [size]="80"></app-loader>
      }

      <header class="content-header">
        <div class="content-header__title">
          <button mat-icon-button class="back-button" (click)="mobileBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>

          @switch (activeTab) {
            @case (UserProfileTab.Info) {
              <h6 @tabTitleAnimation>{{ 'user_profile.profile' | translate }}</h6>
            }
            @case (UserProfileTab.Invitation) {
              <h6 @tabTitleAnimation>{{ 'user_profile.invitation' | translate }}</h6>
            }
            @case (UserProfileTab.Memberships) {
              @switch (membershipsTabMode) {
                @case (TabModes.Overview) {
                  <h6 @tabTitleAnimation>{{ 'user_profile.memberships' | translate }}</h6>
                }
                @case (TabModes.Edit) {
                  <h6 @tabTitleAnimation>{{ selectedMembership!.group.name }}</h6>
                }
              }
            }
            @case (UserProfileTab.Relations) {
              <h6 @tabTitleAnimation>{{ 'user_profile.relations' | translate }}</h6>
            }
            @case (UserProfileTab.Payments) {
              <h6 @tabTitleAnimation>{{ 'user_profile.unpaid_payments' | translate }}</h6>
            }
            @case (UserProfileTab.Chat) {
              <h6 @tabTitleAnimation>{{ 'organization.chat' | translate }}</h6>
            }
          }
        </div>

        <div class="content-header__buttons">
          <ng-container [ngTemplateOutlet]="headerButtons"></ng-container>
        </div>
      </header>

      @if (user) {
        <div class="content-body">
          @switch (activeTab) {
            @case (UserProfileTab.Info) {
              <ng-container [ngTemplateOutlet]="generalInfoTab"></ng-container>
            }
            @case (UserProfileTab.Invitation) {
              <ng-container [ngTemplateOutlet]="invitationTab"></ng-container>
            }
            @case (UserProfileTab.Memberships) {
              <ng-container [ngTemplateOutlet]="membershipsTab"></ng-container>
            }
            @case (UserProfileTab.Relations) {
              <ng-container [ngTemplateOutlet]="relationsTab"></ng-container>
            }
            @case (UserProfileTab.Payments) {
              <ng-container [ngTemplateOutlet]="paymentsTab"></ng-container>
            }
            @case (UserProfileTab.Chat) {
              <ng-container [ngTemplateOutlet]="chatTab"></ng-container>
            }
          }
        </div>
      }
    </section>
  </div>
</div>

<ng-template #headerButtons>
  @if (mainTabMode === TabModes.Overview && membershipsTabMode === TabModes.Overview) {
    <app-expand-y class="action-buttons action-buttons--main">
      @if (
        user && activeTab === UserProfileTab.Info && user.activation_status !== ActivationStatus.WaitingForMembership
      ) {
        <app-expand-x>
          <button mat-icon-button (click)="openEditUserDetails()">
            <mat-icon>edit</mat-icon>
          </button>
        </app-expand-x>
      }
  
      <button class="close-profile-button" mat-icon-button (click)="closeProfile()">
        <mat-icon>close</mat-icon>
      </button>
    </app-expand-y>
  }

  @switch (true) {
    @case (mainTabMode === TabModes.Edit) {
      <app-expand-y class="action-buttons">
        <app-button size="small" [disabled]="userProfileForm.invalid" (click)="saveProfile()">
          {{ 'action.save' | translate }}
        </app-button>
        <app-button
          size="small"
          theme="light"
          class="desktop-action-cancel-button"
          (click)="mainTabMode = TabModes.Overview"
        >
          {{ 'action.cancel' | translate }}
        </app-button>
      </app-expand-y>
    }

    @case (membershipsTabMode === TabModes.Edit) {
      <app-expand-y class="action-buttons">
        <app-button size="small" (click)="saveMembership()">
          {{ 'action.save' | translate }}
        </app-button>
        <app-button
          size="small"
          theme="light"
          class="desktop-action-cancel-button"
          (click)="membershipsTabMode = TabModes.Overview"
        >
          {{ 'action.cancel' | translate }}
        </app-button>
      </app-expand-y>
    }
  }
</ng-template>

<ng-template #generalInfoTab>
  @switch (mainTabMode) {
    @case (TabModes.Overview) {
      <!-- TODO: remove animation when opening details on mobile -->
      <app-general-info
        @contentAnimation
        [@.disabled]="isSmallScreen && mobileView === 'menu' && mainTabMode === TabModes.Overview"
        [hostComponent]="this"
        class="content-container"
        appScrollShadow
      ></app-general-info>
    }

    @case (TabModes.Edit) {
      <app-edit-profile
        @contentAnimation
        [hostComponent]="this"
        class="content-container ns-form ns-light"
        appScrollShadow
      ></app-edit-profile>
    }
  }
</ng-template>

<ng-template #invitationTab>
  <div
    @contentAnimation
    [@.disabled]="isSmallScreen"
    class="content-container content-container--resend-invitation"
    appScrollShadow
  >
    <form [formGroup]="resendInvitationForm" class="ns-form ns-light container">
      <div class="description ns-text small">
        <p>
          {{
            'user_profile.resend_invitation_description'
              | translate: { name: user!.first_name + ' ' + user!.last_name, organization: organization.name }
          }}
        </p>
        <p>{{ 'user_profile.resend_invitation_description_2' | translate: { name: user!.first_name } }}</p>
        <p>
          {{
            'user_profile.resend_invitation_reminder'
              | translate
                : {
                    datetime: lastInvitationDatetime ? (lastInvitationDatetime | date: DateFormat.shortDateTime) : '...'
                  }
          }}
        </p>
      </div>

      <app-info-block type="info" [title]="'user_profile.resend_invitation_by_sms_title' | translate">
        <p
          [innerHTML]="
            'user_profile.resend_invitation_by_sms_description'
              | translate: { name: user!.first_name, number: user!.auth_phone_number, sender: smsSender }
          "
        ></p>
      </app-info-block>

      <div class="cost ns-text small">
        <span>{{ 'sms.cost' | translate }}:</span>
        <span>1 {{ 'sms.credit' | translate }}</span>
      </div>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <div class="submit-button-wrapper">
        <app-button size="small" [disabled]="resendInvitationForm.invalid" (click)="resendInvitation()">
          {{ 'user_profile.resend_invitation_button' | translate }}
        </app-button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #membershipsTab>
  @switch (membershipsTabMode) {
    @case (TabModes.Overview) {
      <app-memberships
        @contentAnimation
        [@.disabled]="isSmallScreen && mobileView === 'menu' && membershipsTabMode === TabModes.Overview"
        [hostComponent]="this"
        class="content-container"
        appScrollShadow
        (editMembership)="openEditMembership($event)"
      ></app-memberships>
    }

    @case (TabModes.Edit) {
      <app-edit-membership
        @contentAnimation
        [membership]="selectedMembership!"
        [hostComponent]="this"
        class="content-container"
        appScrollShadow
      ></app-edit-membership>
    }
  }
</ng-template>

<ng-template #relationsTab>
  <app-relations
    @contentAnimation
    [@.disabled]="isSmallScreen"
    [hostComponent]="this"
    class="content-container"
    appScrollShadow
    (openProfile)="openUserProfile($event)"
    (guardianAdded)="handleGuardianAdded()"
  ></app-relations>
</ng-template>

<ng-template #paymentsTab>
  <app-unpaid-payments @contentAnimation [hostComponent]="this" class="content-container"></app-unpaid-payments>
</ng-template>

<ng-template #chatTab>
  <div @contentAnimation [@.disabled]="isSmallScreen" class="content-container content-container--chat">
    <stream-channel>
      <stream-message-list></stream-message-list>
      <stream-message-input></stream-message-input>
    </stream-channel>
  </div>
</ng-template>

@if (deleteMembershipModal.isOpen) {
  <app-modal
    class="delete-membership-modal"
    [header]="'user_profile.remove_from_group_title' | translate"
    [loading]="deleteMembershipModal.loading"
    [isDismissible]="!deleteMembershipModal.loading"
    (modalClose)="deleteMembershipModal.close()"
  >
    <div class="modal__body">
      <p
        [innerHTML]="
          'user_profile.remove_from_group_description'
            | translate: { user: user | getFullName, group: selectedMembership!.group.name }
        "
        class="default-text-color ns-text"
      ></p>

      @if (isLastAdminMembership) {
        <app-info-block type="warning" [title]="'user_profile.remove_from_group.website_access_lost_title' | translate">
          {{
            'user_profile.remove_from_group.website_access_lost_description'
              | translate: { organization: organization.name }
          }}
        </app-info-block>
      }
    </div>

    <div class="modal__button-wrapper">
      <app-button theme="transparent" size="small" (click)="deleteMembershipModal.close()">
        {{ 'action.no_go_back' | translate }}
      </app-button>
      <app-button size="small" (click)="saveMembership(true)">
        {{ 'action.yes_remove' | translate }}
      </app-button>
    </div>
  </app-modal>
}
